import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class TheElements extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Body and Soul - Reflection Magazine, UK 2008</h1>
                <StaticImage src="../../images/bodyandsoul/ResizedImage600801-2864892.jpg"
                             alt="Body and soul Page 1"/>
                <StaticImage src="../../images/bodyandsoul/ResizedImage600800-91288.jpg"
                             alt="Body and soul Page 2"/>
                <StaticImage src="../../images/bodyandsoul/ResizedImage600801-9714455.jpg"
                             alt="Body and soul Page 3"/>
            </Layout>);
    }
}

export default TheElements;
